import React from 'react';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import {Container, Grid, Typography, Card, CardContent, CardMedia, IconButton, Box} from '@mui/material';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';

function App() {

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));

  const _launchMail = () => {
    window.location.href = "mailto:service@doolaku.com.tw";
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/terms-of-use" element={<TermsOfUse/>}/>
      </Routes>
      <Box style={{
        backgroundColor: '#EAEFF9',
        paddingBottom: 100,
        paddingTop: 100
      }}>
        <Container>
          <Grid container justifyContent={"center"}>
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Box style={{color: '#919AB6', marginBottom: 3}}>客服信箱</Box>
                <Box onClick={()=>_launchMail()}><span style={{ cursor: "pointer", textDecoration: 'underline'}}>service@doolaku.com.tw</span></Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{color: '#919AB6', marginBottom: 3}}>公司及統編</Box>
                <Box>果承有限公司(97562300)</Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{color: '#919AB6', marginBottom: 3}}>服務項目</Box>
                <Box>電商類 | APP開發 | 服務</Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{color: '#919AB6', marginBottom: 3}}>網站導覽</Box>
                <Box>
                  <Link to="/"> 首頁 </Link> |
                  <Link to="/privacy-policy"> 隱私權政策 </Link> |
                  <Link to="/terms-of-use"> 服務條款 </Link>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} justifyContent={'center'}>
              <Box>
                <img src="/image/img_logo.svg" alt="Dooloaku"/>
                <Box mt={1} mb={2}>© 2024 Doolaku. All Rights Reserved.</Box>
                <img width={sm ? '100%' : lg ? '50%' : '50%'} src="/image/img_duns.svg" alt="img_duns"/>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Router>
  );
}

export default App;
